let baseUrl = process.env.REACT_APP_BASE_URL;

const Apis = {
  baseUrl,
  login: baseUrl + "auth/login",
  users: baseUrl + "user",
  leads: baseUrl + "lead",
  meeting: baseUrl + "meeting",
  team: baseUrl + "team",
  booking: baseUrl + "booking",
  leadUpdateById: baseUrl + "lead/leadUpdateById/",
  teamUpdatedById: baseUrl + "team/teamUpdateById/",
  leadAssignById: baseUrl + "lead/leadAssignById",
  updateMeetingById: baseUrl + "meeting/updateMeetingById/",
  deleteMeetingsById: baseUrl + "meeting/deleteMeetingsById",
  bookingApprovedById: baseUrl + "booking/bookingApprovedById",
  bookingRejectedById: baseUrl + "booking/bookingRejectedById",
  deleteLeadsByIds: baseUrl + "lead/deleteLeadsByIds",
  deleteBookingsById: baseUrl + "booking/deleteBookingsById",
  changePassword: baseUrl + "user/changePassword",
  setPassword: baseUrl + "user/setPassword",
  meetingReschedule: baseUrl + "meeting/reScheduleMeetingById",
  meetingConduct: baseUrl + "meeting/conductById",
  notificationSeenById: baseUrl + "user/notificationSeenById",
  getNotificationById: baseUrl + "user/getNotificationById",
};

export default Apis;
