import React, { useEffect, useState } from "react";
import AuthBack from "./AuthBack";
import { Box, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import Logo from "../../assets/AuthLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import BasicDialog from "../../components/BasicDialog";
import EmailVarification from "./EmailVarification";
import { userLogin } from "../../store/actions/authActions";
import LoadButton from "../../components/LoadButton";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.auth);
  const [openForget, setForget] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
  }, [auth, navigate]);

  /****************forget password ***********/
  const handleForgetPassword = () => {
    setForget(true);
  };
  const forgetpasswordClose = () => {
    setForget(false);
  };

  return (
    <Box sx={{ height: "100vh" }}>
      <AuthBack />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box
            sx={{
              position: "absolute",
              bgcolor: "white",
              top: -15,
              left: -76,
              height: "380px",
              width: "380px",
              transform: "rotate(45deg)",
              boxShadow: "2px 3px 14px 0px #00000026",
              borderRadius: 3,
              zIndex: -1,
              "@media(max-width: 600px)": {
                marginTop: "-4rem",
                width: "270px",
                height: "270px",
                position: "absolute",
                zIndex: -1,
                marginLeft: "4.2rem",
              },
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
              "@media(max-width: 600px)": {
                marginTop: "-100px",
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            {/* <img
              src={Logo}
              alt="Logo"
              style={{
                height: "80px",
                width: "120px",
                "@media(max-width: 536px)": {
                  height: "40px",
                  width: "60px",

                }

              }}
             
            /> */}
            <Typography
              sx={{
                height: "80px",
                width: "120px",
                "@media(max-width: 600px)": {
                  height: "40px",
                  width: "60px",
                },
              }}
              component={"img"}
              src={Logo}
            ></Typography>
            <Box
              sx={{
                fontWeight: "bold",
                fontSize: "1.5rem",
                my: 1,
                "@media(max-width: 600px)": {
                  my: 0.5,
                  fontSize: "1rem",
                },
              }}
            >
              Login
            </Box>

            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Email is Required";
                } else if (!values.password) {
                  errors.password = "Password is Required";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address";
                }
                return errors;
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setLoading(true);
                const { type } = await dispatch(userLogin(values));
                setLoading(false);
                if (type === "user/login/fulfilled") {
                  navigate("/dashboard");
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                  }}
                >
                  <TextField
                    type="email"
                    name="email"
                    size="small"
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <Typography
                      sx={{
                        color: "red",
                        "@media(max-width: 600px)": {
                          fontSize: "10px",
                          marginTop: "-0.9rem",
                        },
                      }}
                    >
                      {errors.email}
                    </Typography>
                  )}
                  <TextField
                    type="password"
                    name="password"
                    size="small"
                    placeholder="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && (
                    <Typography
                      sx={{
                        color: "red",
                        "@media(max-width: 600px)": {
                          fontSize: "10px",
                          marginTop: "-0.9rem",
                        },
                      }}
                    >
                      {errors.password}
                    </Typography>
                  )}
                  <Typography
                    onClick={handleForgetPassword}
                    sx={{
                      color: "#FFC857",
                      fontSize: "9px",
                      fontWeight: "600",
                      cursor: "pointer",
                      textAlign: "left",
                      marginTop: "-10px",
                    }}
                  >
                    Forget Password?
                  </Typography>

                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "0.5rem",
                      "@media(max-width: 600px)": {
                        marginTop: "0.2rem",
                      },
                    }}
                  >
                    <LoadButton
                      loading={loading}
                      type="submit"
                      color="custom"
                      variant="contained"
                      sx={{ width: "7rem", color: "#fff" }}
                    >
                      Login
                    </LoadButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
          <BasicDialog open={openForget} handleClose={forgetpasswordClose}>
            <EmailVarification handleClose={forgetpasswordClose} />
          </BasicDialog>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
