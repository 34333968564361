import {
  Dashboard,
  Group,
  Groups,
  Person,
  SupportAgent,
} from "@mui/icons-material";

export const navItems = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: <Dashboard />,
  },
  {
    title: "Leads",
    url: "/leads",
    icon: <Person />,
  },
  {
    title: "Meetings",
    url: "/meetings",
    icon: <Group />,
  },
  {
    title: "Bookings",
    url: "/bookings",
    icon: <Dashboard />,
  },
  {
    title: "Users",
    url: "/users",
    icon: <SupportAgent />,
  },
  {
    title: "Teams",
    url: "/users/teams",
    icon: <Groups />,
  },
];

export const userTypes = {
  sr_manager: "Sr Manager",
  manager: "Manager",
  team_lead: "Team Lead",
  agent: "Agent",
};

export const userTypeArr = [
  { value: "sr_manager", label: "Sr Manager" },
  { value: "manager", label: "Manager" },
  { value: "team_lead", label: "Team Lead" },
  { value: "agent", label: "Agent" },
];

export const status = [
  { value: "assign", label: "Assigned" },
  { value: "conducted", label: "Conducted" },
  { value: "no_response", label: "No Response" },
  { value: "active_cold", label: "Active Cold" },
  { value: "active_hot", label: "Active Hot" },
  { value: "meeting_scheduled", label: "Meeting Scheduled" },
  { value: "wrong_details", label: "Spam/Wrong contact details" },
  { value: "broker", label: "Broker" },
];
export const statusObj = {
  new: "New",
  assign: "Assigned",
  conducted: "Conducted",
  no_response: "No Response",
  active_cold: "Active Cold",
  active_hot: "Active Hot",
  meeting_scheduled: "Meeting Scheduled",
  wrong_details: "Spam/Wrong contact details",
  broker: "Broker",
};

export const meetingStatus = {
  schedule: "Meeting Scheduled",
  conducted: "Meeting Conducted",
  reschedule: "Meeting Rescheduled",
};

export const leadType = [
  { value: "lead", label: "Lead" },
  { value: "calling_data", label: "Calling Data" },
];

export const leadTypeObj = { lead: "Lead", calling_data: "Calling Data" };

export const developers = [
  { value: "Kashif", label: "Kashif" },
  { value: "Rajat", label: "Rajat" },
];

export const paymentMode = [
  { value: "cash", label: "Cash" },
  { value: "cheque", label: "Cheque" },
  { value: "bank_tranfer", label: "Bank Transfer" },
];
export const paymentModeObj = {
  cash: "Cash",
  cheque: "Cheque",
  bank_tranfer: "Bank Transfer",
};

export const token = [
  { value: true, label: "Paid" },
  { value: false, label: "UnPaid" },
];
