import { Box } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { getLeads } from "../store/reducers/lead";
import { getUsers } from "../store/reducers/user";
import { getTeams } from "../store/reducers/team";
import { getMeetings } from "../store/reducers/meeting";
import { getBookigs } from "../store/reducers/booking";

const MainLayout = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { leads } = useSelector((state) => state.leads);
  const { users } = useSelector((state) => state.users);
  const { teams } = useSelector((state) => state.teams);
  const { meetings } = useSelector((state) => state.meetings);
  const { bookings } = useSelector((state) => state.bookings);

  const getData = useCallback(() => {
    if (leads.length === 0) {
      dispatch(getLeads());
    }
    if (users.length === 0) {
      dispatch(getUsers());
    }
    if (teams.length === 0) {
      dispatch(getTeams());
    }
    if (meetings.length === 0) {
      dispatch(getMeetings());
    }
    if (bookings.length === 0) {
      dispatch(getBookigs());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!auth) {
      navigate("/auth/login");
      return;
    } else if (pathname === "/") {
      navigate("/dashboard");
    }
    getData();

    //remove pathname from dependencies due to extra api calls
  }, [auth, navigate, getData]);

  if (!auth) {
    navigate("/auth/login");
    return null;
  }

  return (
    <Box>
      <Header />
      <Navbar />
      <Box sx={{ px: "1.5rem", pb: 4 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
