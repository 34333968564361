import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";

const initialState = {
  teams: [],
  teamsLoading: false,
};

export const getTeams = createAsyncThunk("teamList", async (thunkApi) => {
  try {
    const { data } = await API_AXIOS.get(Apis.team);

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const teamSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state) => {
      state.teamsLoading = true;
    });
    builder.addCase(getTeams.fulfilled, (state, { payload }) => {
      state.teamsLoading = false;
      state.teams = payload;
    });
    builder.addCase(getTeams.rejected, (state) => {
      state.teamsLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default teamSlice.reducer;
