import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_AXIOS } from "../../http/interceptor";
import Apis from "../../utils/apis";

const initialState = {
  users: [],
  usersLoading: false,
};

export const getUsers = createAsyncThunk("usersList", async (thunkApi) => {
  try {
    const { data } = await API_AXIOS.get(Apis.users);

    return data;
  } catch (err) {
    console.log(err);
    return [];
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.usersLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.usersLoading = false;
      state.users = payload;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.usersLoading = false;
    });
  },
});

// Action creators are generated for each case reducer function
// export const {} = counterSlice.actions;

export default userSlice.reducer;
