import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "../actions/authActions";

const userInfo = localStorage.getItem("info");

const initialState = {
  auth: JSON.parse(userInfo) || null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNotifications: (state, { payload }) => {
      const userInfo = JSON.parse(localStorage.getItem("info"));

      userInfo.notifications = payload;
      state.auth.notifications = payload;
      localStorage.setItem("info", JSON.stringify(userInfo));
    },
    logout: (state) => {
      localStorage.removeItem("token");
      state.auth = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.auth = payload;
    });
    builder.addCase(userLogin.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { logout, setNotifications } = authSlice.actions;

export default authSlice.reducer;
