import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
//import { toast } from "react-toastify";
import { Formik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import Apis from "../../utils/apis";
import LoadButton from "../../components/LoadButton";

const EmailVarification = ({ handleClose }) => {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Box>
        <Typography
          onClick={handleClose}
          sx={{ textAlign: "right", cursor: "pointer" }}
        >
          <CloseIcon />
        </Typography>
        <Typography
          variant="h5"
          sx={{ textAlign: "center", fontWeight: "600" }}
        >
          Email Verification
        </Typography>
        <Divider
          sx={{ backgroundColor: "#FFC857", width: "13rem", margin: "auto" }}
        />
        <Box sx={{ textAlign: "center ", marginTop: "1rem", width: "21rem", "@media(max-width: 600px)":{
             width: "17rem", textAlign: "center"
        } }}>
          <Formik
            initialValues={{
              email: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={async (values) => {
              setLoading(true);
              try {
                const { data } = await axios.post(
                  `${Apis.users}/forgetPassword`,
                  values
                );
                toast.success(data);
                handleClose();
              } catch (err) {
                toast.error(err.response.data);
              }
              setLoading(false);
            }}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  sx={{ marginTop: "2rem", "@media(max-width: 600px)":{
                        marginTop: "1rem"
                  } }}
                  label="Email"
                  size="small"
                  placeholder="Enter Email Address"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                
                />
                <Typography
                  sx={{ color: "red", textAlign: "left", fontSize: "11px" }}
                >
                  {errors.email}
                </Typography>

                <LoadButton
                  type="submit"
                  loading={loading}
                  variant="contained"
                  color="custom"
                  sx={{ mt: 2 }}
                >
                  Submit
                </LoadButton>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default EmailVarification;
