import { lazy } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import Loadable from "./components/Loadable";
// import Login from "./pages/Login";
import MainLayout from "./layouts/MainLayout";
// import UserList from "./pages/Users/UserList";
import "./App.css";
import { Box } from "@mui/material";

//lazy loading
import Login from "./pages/Login";
// const Login = Loadable(lazy(() => import("./pages/Login")));
const Forget = Loadable(lazy(() => import("./pages/Forget/ForgetPassword")));
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard")));
const UserList = Loadable(lazy(() => import("./pages/Users/UserList")));
const AddUser = Loadable(lazy(() => import("./pages/Users/AddUser")));
const AddTeam = Loadable(lazy(() => import("./pages/Teams/AddTeam")));
const TeamList = Loadable(lazy(() => import("./pages/Teams/TeamList")));
const LeadList = Loadable(lazy(() => import("./pages/Leads/LeadList")));
const AddLead = Loadable(lazy(() => import("./pages/Leads/AddLead")));
const LeadDetails = Loadable(lazy(() => import("./pages/Leads/LeadDetails")));
const MeetingList = Loadable(
  lazy(() => import("./pages/Meetings/MeetingList"))
);
const AddMeeting = Loadable(lazy(() => import("./pages/Meetings/AddMeeting")));
const MeetingDetails = Loadable(
  lazy(() => import("./pages/Meetings/MeetingDetails"))
);
const AddBooking = Loadable(lazy(() => import("./pages/Bookings/AddBooking")));
const BookingList = Loadable(
  lazy(() => import("./pages/Bookings/BookingList"))
);
const BookingDetails = Loadable(
  lazy(() => import("./pages/Bookings/BookingDetails"))
);

function App() {
  const router = createBrowserRouter([
    {
      path: "/auth/login",
      element: <Login />,
    },
    {
      path: "/auth/forget",
      element: <Forget />,
    },

    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "users",
          element: <UserList />,
        },
        {
          path: "users/addUser",
          element: <AddUser />,
        },
        {
          path: "users/addTeam",
          element: <AddTeam />,
        },
        {
          path: "users/teams",
          element: <TeamList />,
        },
        {
          path: "leads",
          element: <LeadList />,
        },
        {
          path: "leads/:id",
          element: <LeadDetails />,
        },
        {
          path: "leads/addLead",
          element: <AddLead />,
        },
        {
          path: "meetings",
          element: <MeetingList />,
        },
        {
          path: "meetings/addMeeting",
          element: <AddMeeting />,
        },
        {
          path: "meetings/:id",
          element: <MeetingDetails />,
        },
        {
          path: "bookings",
          element: <BookingList />,
        },
        {
          path: "bookings/addBooking",
          element: <AddBooking />,
        },
        {
          path: "bookings/:id",
          element: <BookingDetails />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#f8f8f8",
      }}
    >
      <h1>An error occurred</h1>
      <p>Sorry, something went wrong.</p>
    </Box>
  );
}
export default App;
