import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    custom: {
      main: "#FDDB32",
    },
  },
  typography: {
    button: {
      textTransform: "unset",
    },
  },
});
