import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import userReducer from "./reducers/user";
import leadReducer from "./reducers/lead";
import teamReducer from "./reducers/team";
import meetingReducer from "./reducers/meeting";
import bookingReducer from "./reducers/booking";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    leads: leadReducer,
    meetings: meetingReducer,
    teams: teamReducer,
    bookings: bookingReducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
});
